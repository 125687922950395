import { NgModule } from '@angular/core';
import { environment } from '../../environments/environment';

@NgModule({
  imports: [

  ],
  declarations: []
})
export class Helper {

  public static api(path){
    return environment.apiBaseUrl+'/'+path;
  }



  public static asset(path){
    return environment.baseUrl+'/'+path;
  }

  public static fbAppId() {
    return environment.fbAppId;
  }

  public static handleError(error: any, redirectUrl?: string): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error.json());
  }

}
