import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";



const routes: Routes = [
  { path: 'manage', loadChildren: () => import('./bo/bo.module').then(m => m.BOModule)},
  { path: '', loadChildren: () => import('./fo/fo.module').then(m => m.FOModule)},
  /*{ path: '', loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule) },*/
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy'
    }),
    CommonModule
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
