import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

function bootstrap() {

  const rootElement = document.querySelector(".root-element");
  const loadingElement = document.querySelector(".app-loading");
  platformBrowserDynamic().bootstrapModule(AppModule)
    .then(() => loadingElement.classList.add("loaded"))
    // remove the loading element after the transition is complete to prevent swallowed clicks
    .then(() => setTimeout(() => {
                                loadingElement.remove()
                                rootElement.classList.remove("none")
                              }, 100))
    .catch(err => console.error(err));

  /*document.addEventListener("DOMContentLoaded", () => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(err => console.log(err));
  });*/
}


if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}

