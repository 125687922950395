export const environment = {
   production: false,
   pro: true,
   iframe: false,
//  apiBaseUrl: "https://api.akces.fr/api",
 // baseUrl: "https://api.akces.fr",
 // fbAppId: "259796547837759",
    apiBaseUrl: "https://dev-api.akces.fr/api",
    baseUrl: "https://dev-api.akces.fr",
    fbAppId: "259796547837759",
    backOfficeUrl:  "https://dev-app.akces.fr",
    appUrl: "dev.akces.fr",
    dddSecureUrl: "https://preprod-tpeweb.e-transactions.fr/cgi/RemoteMPI.cgi"
    /*apiBaseUrl: "http://localhost:8000/api",
    baseUrl : "http://localhost:8000",
    fbAppId : "1974402976133412"*/


};




