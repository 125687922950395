import {Component, PLATFORM_ID, Inject} from '@angular/core';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'akces';
  loading:boolean = false;

  constructor(private router: Router,@Inject(PLATFORM_ID) private platformId: Object,private translateService: TranslateService) {
          /* if(!fonctionUtils.retrieve('curentLang') || fonctionUtils.retrieve('curentLang') == 'fr'){
               translate.setDefaultLang('fr');
               fonctionUtils.store('curentLang','fr');
               fonctionUtils.store('curentLangFlag',fonctionUtils.LangFlagFR);
           } else {
             translate.setDefaultLang(fonctionUtils.retrieve('curentLang'));
           }*/

    this.translateService.addLangs(['en', 'fr']);
    this.translateService.setDefaultLang('en');
  }


  ngOnInit() {
  }

}
